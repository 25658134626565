module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":""},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["","",""],"gtagConfig":{"anonymize_ip":false,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false,"exclude":["/preview/**"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
